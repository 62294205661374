<template>
  <div class="order-detail-header">
    <img
      :src="image"
      width="48"
      height="48"
      alt="ไม่พบเลขที่ใบจองหรือทำรายการไม่สำเร็จ"
    />

    <div>
      <div class="title-medium">รายการสั่งซื้อ #{{ order }}</div>
      <div>
        <div class="headline-m">
          {{ title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OrderDetailHeader',
  props: {
    image: {
      type: String,
    },
    order: {
      type: String,
    },
    title: {
      type: String,
    },
  },
}
</script>

<style lang="stylus" scoped>
.order-detail-header {
    /* Your styles here */
}

.title-medium {
    /* Your styles here */
}

.headline-m {
    /* Your styles here */
}
</style>
